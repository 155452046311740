<template>
<div>
  <b-container fluid class="bg-white my-4">
    <b-row class="mb-4">
      <b-col>
        <div style="display: flex; height: 30px;">
          <filter-interface class="mr-4" namespace="tax" :rows="rows" v-model="rowsFiltered" :staticDataProperties="staticDataProperties" :includeTaxonomy="true"></filter-interface>
          <search-interface namespace="tax" :rows="rowsFiltered" v-model="rowsSearched"></search-interface>
        </div>
      </b-col>
    </b-row>
    <b-row cols="2">
      <b-col>
        <div class="mb-4" v-for="chart in barCharts1" :key="chart.key">
          <bar-stacked-simple v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
        </div>
      </b-col>
      <b-col>
        <div class="mb-4" v-for="chart in barCharts2" :key="chart.key">
          <bar-stacked-simple v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import * as echarts from 'echarts'
import dataGetters from '@/libs/dataGetters'
import theme from '@/libs/colorTheme'
import workflows from '@/workflows/initiative'
import BarStackedSimple from '@/components/charts/v1/barStackedSimple.vue'
import FilterInterface from '@/components/FilterInterfaceV4.vue'
import SearchInterface from '@/components/SearchInterfaceV1.vue'
echarts.registerTheme('kpmg', theme)

function getHeight (data) {
  return (Math.floor(data.length / 10) + 1) * 140 + 'px'
}

function getRandomKey () {
  return Math.floor(Math.random() * 10000)
}

export default {
  name: 'TaxDataStrats',
  components: {
    BarStackedSimple,
    FilterInterface,
    SearchInterface
  },
  computed: {
    barCharts1: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'property',
          property: 'country',
          nameProperty: 'name',
          nameSeries: 'Initiatives',
          header: 'Initiatives per country',
          headerRecord: 'countries'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rowsSearched, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: getHeight(data),
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    barCharts2: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'array',
          property: 'impacttopics',
          nameProperty: 'name',
          nameSeries: 'Initiatives',
          header: 'Initiatives per topic',
          headerRecord: 'topics'
        },
        {
          dataGetter: 'object',
          property: 'impactstatus',
          nameProperty: 'name',
          nameSeries: 'Initiatives',
          header: 'Initiatives per status',
          headerRecord: 'statuses'
        },
        {
          dataGetter: 'workflow',
          workflows: workflows,
          property: 'workflow_id',
          nameProperty: 'name',
          nameSeries: 'Initiatives',
          header: 'Initiatives per workflow stage',
          headerRecord: 'stages'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rowsSearched, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: getHeight(data),
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    rows: function () {
      return this.$store.state.initiativesTax
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      rowsFiltered: [],
      rowsSearched: [],
      staticDataProperties: [
        { name: 'Countries', property: 'country', direct: true },
        { name: 'Sectors', property: 'sectors', graph: 'sectors', sectors: true },
        { name: 'Status', property: 'impactstatus', graph: 'impactstatus', tree: true },
        { name: 'Workflow', property: 'workflow', direct: true }
      ]
    }
  },
  created: function () {
    document.title = "Tax Dashboard - Data stratification"
    console.log(this.rows)
  }
}
</script>

<style>
</style>
